.login-form-page {
  display: block;
  justify-content: center;
  width: 100%;

  .title {
    color: var(--text1);
    font-size: 24px;

    margin-top: 10%;

    @media screen and (max-width: 720px) {
      margin-top: -15%;
      font-size: 18px;
    }

    padding-left: 12%;
  }

  .title-row {
    display: flex;
    justify-content: center;

    .logo {
      width: var(--logo-xl-width);
      margin-top: 30px;
      height: 60px;
    }
  }

  // @media screen and (max-width: 720px) {
  //   display: flex;
  //   justify-content: center !important;
  //   width: 100vw;
  // }
  .login-form-ctn {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 350px;

    @media screen and (max-width: 720px) {
      width: 300px;
    }

    .info {
      margin: 10px 45px;
      font-size: 14px;
      color: var(--text1);
      text-align: center;
    }

    .login-err-msg {
      font-size: 11px;
      text-align: center;
      color: red !important;
    }

    span,
    div {
      color: var(--text-primary) !important;
    }

    .form-title {
      font-size: 22px;
    }

    .login-and-demo-login {
      display: flex;
      justify-content: space-around;
      margin-top: 2.5rem;

      .login-form-btn {
        height: 45px;
        width: 48%;
        background-color: var(--ion-color-primary);
        border-radius: 13px;

        .MuiButton-label {
          color: var(--text3) !important;
          font-weight: 600;
          font-size: 14px;
          font-family: var(--font-family);
          text-transform: capitalize;

          .icon {
            margin-right: 10px;
            height: 20px;
            color: var(--text3) !important;
          }
        }
      }

      .login-form-btn-without-demologin {
        height: 45px;
        width: 100%;
        background-color: var(--ion-color-primary);
        border-radius: 13px;

        .MuiButton-label {
          color: var(--text3) !important;
          font-weight: 600;
          font-size: 14px;
          font-family: var(--font-family);
          text-transform: capitalize;

          .icon {
            margin-right: 10px;
            height: 20px;
            color: var(--text3) !important;
          }
        }
      }
    }

    .back {
      text-align: center;
      color: var(--text1) !important;
      margin-top: 10px;
      color: #fff;

      @media screen and (max-width: 720px) {
        min-width: 300px !important;
        max-width: 400px !important;
      }

      .navlink {
        text-decoration: none;
        color: var(--text1) !important;
      }
    }

    .bottom-link {
      margin-top: auto;
      margin-bottom: 1rem;
      font-size: 14px;
      text-align: center;

      .register-link {
        color: var(--card);
        cursor: pointer;
      }
    }

    .form-title {
      margin-top: 1rem;
      color: #fff !important;
      text-align: center;
    }

    .usr-input,
    .pwd-input {
      margin-top: 0.7rem;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 720px) {
        min-width: 300px !important;
        max-width: 400px !important;
        margin-top: 15px;
      }

      border: 0px !important;

      .input-label {
        color: var(--ion-text-white);

        @media screen and (max-width: 720px) {
          margin-top: 10px;
        }
      }

      .MuiIconButton-root {
        padding: 0;
      }

      .MuiOutlinedInput-adornedEnd {
        background-color: var(--login-input-bg);
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .login-form-page {

    .login-form-ctn {
      .login-and-demo-login {
        flex-direction: column;
        margin-top: 1.5rem;

        .login-form-btn {
          height: 45px;
          width: 100%;
          background-color: var(--ion-color-primary);
          border-radius: 13px;
          margin-top: 10px;

          .MuiButton-label {
            color: var(--text3) !important;
            font-weight: 600;
            font-size: 14px;
            font-family: var(--font-family);
            text-transform: capitalize;

            .icon {
              margin-right: 10px;
              height: 20px;
              color: var(--text3) !important;
            }
          }
        }
      }
    }
  }

}

@media (min-width: 1024px) {
  .login-form-ctn {
    min-height: 100vh;
  }
}