.modal-close-btn {
  position: absolute !important;
  top: 8px;
  right: 8px;
  color: #9e9e9e;

  .MuiIconButton-label {
    svg {
      fill: var(--text-primary);
    }
  }
}

.MuiDialog-paper {
  .MuiDialogContent-root {
    .modal-body {
      padding: 14px !important;
    }
  }

  .MuiDialogActions-root {
    background: var(--card);
    justify-content: center;
  }
}
.dialog-login {
  .modal-close-btn {
    position: absolute !important;
    top: 8px;
    right: 8px;
    color: #9e9e9e;

    .MuiIconButton-label {
      svg {
        display: none;
        fill: var(--text-primary);
      }
    }
  }
  background: none !important;
  .dialog-div-body {
    background-color: var(--common-dark);
    color: var(--ion-text-white);
    overflow: hidden;
    height: 350px;
    .go-corner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 38px;
      height: 38px;
      overflow: hidden;
      top: 0;
      right: 0;
      color: var(--text-primary) !important;
      background: var(--ion-color-primary) !important;
      border-radius: 0 4px 0 32px;
      cursor: pointer;
      .go-arrow {
        margin-top: -4px;
        margin-right: -4px;
        color: var(--ion-text-white);
      }
    }
  }
}
@media screen and (max-width: 720px) {
  .costom-dialog,
  .dialog-login {
    padding: 0px !important;
    margin: 0px;
    .dialog-div-body {
      min-height: 100% !important;
      max-height: 100% !important;
      background-color: var(--common-dark);
      color: var(--ion-text-white);
      display: block;
      justify-content: center;
      align-items: center;
    }
  }
}
.MuiDialog-root .MuiDialog-container .MuiDialog-paper .MuiDialogContent-root {
  @media screen and (max-width: 720px) {
    height: 70vh;
    color: var(--ion-text-white);
  }
}
.costom-dialog {
  .modal-close-btn {
    position: absolute !important;
    top: 8px;
    right: 8px;
    color: #9e9e9e;
    .MuiIconButton-label {
      svg {
        display: none;
        fill: var(--text-primary);
      }
    }
  }
  background: none !important;
  .dialog-div-body {
    background-color: var(--common-dark);
    color: var(--ion-text-white);
    overflow: hidden;
    .go-corner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 38px;
      height: 38px;
      overflow: hidden;
      top: 0;
      right: 0;
      color: var(--text-primary) !important;
      background: var(--ion-color-primary) !important;
      border-radius: 0 4px 0 32px;
      cursor: pointer;
      .go-arrow {
        margin-top: -4px;
        margin-right: -4px;
        color: var(--ion-text-white);
      }
    }
  }
}
.MuiDialog-paperWidthSm {
  border-radius: 20px !important;
  box-shadow: none !important;
}
.modal {
  box-shadow: none !important;
  border-radius: 20px !important;
  .MuiDialog-paperWidthSm {
    border-radius: 20px !important;
    box-shadow: none !important;
    min-width: 70%;
    @media screen and (max-width: 720px) {
      min-width: 90%;
    }
  }
  .carw {
    height: 60px;
    .arrow {
      position: relative;
      width: 200px !important;
      background-color: var(--common-dark);
      line-height: 60px;
      margin-bottom: 30px;
      border-top-left-radius: 10px;
      border-top-right-radius: 35px;
      padding-left: 20px !important;
      font-size: 20px;
      color: var(--ion-text-white);
      font-weight: 500;
      .sub-title {
        font-size: 14px;
      }
    }
    .arrow-right:after {
      content: '';
      width: 0px;
      height: 0px;
      position: absolute;
      right: -38px;
      top: 0;
      border-top-left-radius: 10px;
      border-right: 60px solid transparent;
      border-bottom: 60px solid var(--common-dark);
    }
  }
  .modal-content {
    // min-height: 120px;
    color: var(--ion-text-white);
    background-color: var(--common-dark);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    padding: 4px;
    .go-corner {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      width: 20px;
      height: 20px;
      overflow: hidden;
      top: 55px;
      right: 20px;
      z-index: 9999;
      border-radius: 50px;
      cursor: pointer;
      .go-arrow {
        color: var(--text);
        font-family: courier;
        height: 20px;
        margin-top: -3px;
      }
    }
  }
}
