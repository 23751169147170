.right-side-nav {
  position: sticky !important;
  top: 0;
  padding: 2px;
  border-radius: 10px;
  background-color: var(--ion-background);
  .MuiPaper-root .MuiTableBody-root .MuiTableCell-body {
    font-family: var(--font-family) !important;
    padding: 4px;
    border-bottom: 0px !important;
    font-size: 11px;
    
  }
  .right-side-accordion {
    width: 100%;
    margin-bottom: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-bottom: 10px !important;
    .stream-header {
      height: 50px;
      width: 100%;
      .header-title{
        padding: 10px;
      }
      .MuiAccordionSummary-expandIcon {
        color: var(--text-primary) !important;
      }
    }
    .stream-body{
      margin-bottom: 10px;
    }
    .navlink{
      display: flex;
      justify-content: center;
    }
  }
  .betslip-section {
    width: 100%;
    display: flex;
    margin: 0;
    bottom: 50px;
    left: 0;
    right: 0;
    margin-bottom: 10px;
    &.ios-betslip {
      transform: translate3d(0, 0, 0) !important;
      position: -webkit-sticky !important;
      bottom: 0px !important;
      min-width: 100%;
      width: 100%;
    }
    .right-side-accordion {
      width: 100%;
      margin-bottom: 10px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      margin-bottom: 10px !important;
      .stream-header {
        height: 50px;
        width: 100%;
        .header-title{
          padding: 10px;
        }
        .MuiAccordionSummary-expandIcon {
          color: var(--text-primary) !important;
        }
      }
      .stream-body{
        margin-bottom: 10px;
      }
    }
  }
}

.right-side-nav1 {
  .offers-section {
    padding: 10px;
    .offsers-list {
      .list-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 30px;
        text-transform: capitalize;
        color: var(--ion-text-white);
        img {
          margin-right: 5px;
        }
      }

      .list-item {
        margin-bottom: 5px;
        .header {
          color: var(--ion-text-white);
          margin: 5px 0;
          font-size: 12px;
          letter-spacing: normal;
        }

        .desc {
          color: var(--ion-text-white);
          font-size: 12px;
          margin: 5px 0;
          letter-spacing: normal;
        }
      }
    }
  }
  padding: 4%;
  .adv-banner-ctn {
    height: 100%;
    .casino-advt-img {
      margin-top: 8px;
      border-radius: 10px;
    }
    .content {
      position: relative;
      width: 88%;
      max-width: 400px;
      margin: auto;
      overflow: hidden;
      border-radius: 10px;
    }

    .content .content-overlay {
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      height: 98%;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      -webkit-transition: all 0.4s ease-in-out 0s;
      -moz-transition: all 0.4s ease-in-out 0s;
      transition: all 0.4s ease-in-out 0s;
    }

    .content:hover .content-overlay {
      opacity: 1;
    }

    .content-image {
      width: 100%;
      border-radius: 10px;
    }

    .content-details {
      position: absolute;
      text-align: center;
      padding-left: 1em;
      padding-right: 1em;
      width: 100%;
      top: 50%;
      left: 50%;
      opacity: 0;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-transition: all 0.3s ease-in-out 0s;
      -moz-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
    }

    .content:hover .content-details {
      top: 50%;
      left: 50%;
      opacity: 1;
    }

    .content-details h3 {
      color: var(--text-primary);
      font-weight: 500;
      letter-spacing: 0.15em;
      margin-bottom: 0.5em;
      text-transform: uppercase;
    }

    .content-details p {
      color: var(--text-primary);
      font-size: 0.8em;

      .custom-btn {
        width: 60px;
        height: 25px;
        color: var(--text-primary);
        border-radius: 5px;
        padding-top: 5px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease;
        background: var(--ion-color-primary);
        position: relative;
        display: inline-block;
        outline: none;
      }
    }

    .fadeIn-bottom {
      top: 80%;
    }

    .fadeIn-top {
      top: 20%;
    }

    .fadeIn-left {
      left: 20%;
    }

    .fadeIn-right {
      left: 80%;
    }
  }
}
