.main-header-ctn {
  width: 100%;
  padding: 0 18px;
  background: var(--ion-header-background) !important;
  height: 60px;
  margin-top: 0% !important;

  .branding-ctn {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 2px !important;
    width: 50%;
    .site-title {
      left: 23px;
      margin-top: 4px !important;
      .mob-logo {
        margin-top: 10px;
      }

      .site-logo {
        width: 66.21px !important;
        height: 57px;
        margin-top: 2px !important;
        @media screen and (max-width: 720px) {
          width: 50.21px !important;
          height: 50px;
        }
      }
    }

    .search1 {
      margin-left: 10px;
      width: 250px;
      position: relative;
      display: flex;
      .inner-search2 {
        width: inherit;
        font-size: 12px !important;
        .search-event {
          width: inherit;
          outline: none;
          height: 30px;
          background-color: var(--search-background) !important;
          box-shadow: 0 1px 6px 0 #20212447;
        }
        .search-event-focus {
          width: inherit;
          outline: none;
          height: 30px;
          background-color: white !important;
          color: black !important;
          box-shadow: 0 1px 6px 0 #20212447;
          border-radius: 10px 10px 0 0 !important;
        }
        .search-event::placeholder {
          font-size: 12px !important;
        }
        .search-event-focus::placeholder {
          font-size: 12px !important;
          color: black;
        }
      }
      .event-list-drop-down {
        position: absolute;
        width: inherit;
        max-height: 300px;
        margin-top: 30px;
        background-color: white;
        color: black;
        overflow-y: scroll;
        overflow-x: hidden;
        z-index: 1000;
        border-radius: 0 0 10px 10px;
        box-shadow: 0 1px 6px 0 #20212447;

        .event-drop-down {
          padding: 10px 0px 10px 20px;
          font-size: 12px;
          cursor: pointer;
        }

        .event-drop-down:hover {
          color: black;
          background-color: var(--ion-color-primary);
        }
      }
      .event-list-drop-down-duplicate {
        position: absolute;
        width: inherit;
        max-height: 300px;
        margin-top: 30px;
        background-color: white;
        color: black;
        overflow-y: scroll;
        overflow-x: hidden;
        z-index: 1000;
        border-radius: 0 0 10px 10px;
        box-shadow: 0 1px 6px 0 #20212447;

        .event-drop-down {
          padding: 10px 0px 10px 20px;
          font-size: 12px;
          cursor: pointer;
        }

        .event-drop-down:hover {
          color: black;
          background-color: var(--ion-color-primary);
        }
      }
    }
    .remove-query {
      height: 20px;
      width: 30px;
      font-size: 18px;
      position: relative;
      right: 20px;
      color: black;
    }

    .no-display {
      display: none;
    }
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    padding: 0;
    height: auto;
    .domain-change-logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      img {
        height: 50px;
      }
    }
  }

  .h-btns {
    font-size: 18px;
    font-weight: 600;
    color: var(--tab-text);
    background: var(--card);
    letter-spacing: 0.02em;
  }

  .live-clock {
    color: var(--tab-text);
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 35%;
    margin-left: 18px;

    .date {
      opacity: 1;
    }
  }

  .header-actions {
    display: flex;

    font-family: var(--font-family);
    font-size: 12px;

    .header-right-actions {
      display: flex;
      align-items: center;
      font-family: var(--font-family);
      font-size: 13px;
      cursor: pointer;

      .profile-section {
        display: flex;
        text-transform: capitalize;

        .btn-header {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .h-list-item {
        .notify {
          height: 30px;
          width: 35px;
          margin-right: 2px;
          margin-top: 4px;
          color: var(--ion-text-white) !important;
          fill: var(--ion-text-white) !important;
          filter: var(--game-category-icons1);
        }
      }
    }
  }
}

.user-popover-list {
  .nav-link {
    color: transparent !important;
    font-family: var(--font-family);
    font-size: 12px;
  }

  .ion-list-item {
    color: var(--text-primary) !important;
    font-family: var(--font-family);
    font-size: 12px;
  }

  span,
  div {
    color: var(--text-primary) !important;
    font-family: var(--font-family);
    font-size: 12px;
  }
}

@media screen and (max-width: 885px) {
  .main-header-ctn {
    .header-row {
      .search1 {
        display: none;
      }
    }
  }
}

// @media screen and (max-width: 777px) {

// }

@media screen and (max-width: 777px) {
  .main-header-ctn {
    padding: 0px;
    height: auto;

    .h-btns {
      padding: 2px 20px;
    }

    .live-clock {
      display: none;
    }

    .header-actions {
      width: 68%;
      height: 100%;
      justify-content: flex-end;

      .header-right-actions {
        display: flex;
        flex-direction: row;
        text-transform: capitalize;

        .profile-section {
          display: flex;
          flex-direction: row;
          padding: 0px 10px;
          height: 100%;
          text-transform: capitalize;

          .user-icon {
            .MuiListItemAvatar-root {
              margin-top: 0.3rem;
            }
          }
        }

        .h-list-item {
          min-width: 65px;
          font-size: 10px;
          font-weight: 600;
          margin-top: auto;
          margin-bottom: auto;
          text-transform: capitalize;

          .btn-content {
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }
    }
  }
}

.input-wrapper {
  font-family: var(--font-family);
  font-size: 12px;
}

@media screen and (max-width: 720px) {
  .MuiAppBar-colorPrimary {
    height: 53px;
  }
}

.MuiAppBar-colorPrimary {
  color: var(--ion-text-white) !important;
}
