.bets-list-popper-ctn {
  background-color: var(--common-dark);
  padding: 20px;
  .title {
    color: var(--ion-color-primary);
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }
  .btn {
    margin-top: 10px;
    border-radius: 8px !important;
    background-color: var(--ion-color-primary);
    color: var(--common-dark);
    font-weight: bold;
    text-transform: capitalize;
    font-size: 13px;
  }
  .bets-ctn {
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    padding: 2px;
    .MuiPaper-root {
      margin: 0px;
      border-radius: 8px !important;
      background-color: var(--ion-background);
      .MuiTableHead-root .MuiTableRow-head {
        .MuiTableCell-head {
          font-size: 13px !important;
          font-family: var(--font-family);
          color: var(--teble-head) !important;
          padding: 6px !important;
        }
      }
      .MuiTableBody-root {
        background-color: var(--ion-background);
        font-size: 12px !important;
        .MuiTableRow-root {
          color: var(--teble-head) !important;
          .MuiTableCell-body {
            color: var(--teble-head) !important;
            font-size: 12px !important;
            padding: 6px !important;
            .market-name-col {
              white-space: nowrap;
              width: 100px !important;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    .MuiTableSortLabel-root:hover {
      color: var(--table-head) !important;
    }
    .no-data-row {
      .no-data-cell {
        font-size: 12px;
        color: var(--text-primary) !important;
      }
    }
  }
}
