.main-header-ctn {
  border-radius: 0px;

  .header-row {
    .site-title .logo {
      height: var(--header-logo-height);
      width: var(--header-logo-width);
    }
  }

  .header-whatsapp-icon {
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;

    @media screen and (max-width: 720px) {
      margin-right: 10px;
    }
  }

  .header-actions {
    display: flex;
    align-items: center;
    margin-right: 20px;
    @media screen and (max-width: 720px) {
      margin-right: 10px;
    }
  }

  .center-option-ctn {
    .nav-link1 {
      text-decoration: none;
    }
    .active {
      border-bottom: 2px solid var(--ion-text-white);
    }
  }
  .balance-btn {
    padding: 4px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 110px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 720px) {
      min-width: 80px;
      font-size: 12px;
    }
    .active-color {
      color: var(--ion-color-primary);
      margin-right: 4px;
    }
    .icon {
      margin-right: 4px;
      color: var(--ion-text-white);
      fill: var(--ion-text-white);
      @media (max-width: 720px) {
        margin-right: 3px !important;
      }
    }
    .block-icon {
      font-size: 18px;
      margin-right: 4px;
      @media (max-width: 720px) {
        margin-right: 3px;
        font-size: 15px;
      }
    }
  }

  .btn {
    background: transparent !important;
    border-radius: 8px;
    border: 1px solid var(--filter-border) !important;
    .MuiButton-label {
      color: var(--ion-color-primary);
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
    }
    border: none;
    transition: all 0.3s ease;
    overflow: hidden;
  }
  .theme-options {
    border-radius: 30px;
    border: 1px solid var(--filter-border) !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 35px;
    padding: 4px;
    margin-left: 4px;
    .circle1 {
      width: 30px;
      height: 30px;
      border-radius: 50px;
      background: var(--common-dark);
      border: 1px solid var(--filter-border) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 2px;
      margin-right: 2px;
      .round1 {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (min-width: 320px) and (max-width: 480px) {
          width: 22px;
          height: 22px;
        }
        color: var(--text-primary);

        .moon-img {
          color: var(--text-primary);
          fill: var(--text-primary);
        }
      }
      .round {
        width: 22px;
        height: 22px;
        border-radius: 50px;
        &.bg1 {
          background: linear-gradient(
            90deg,
            var(--th1-primary),
            var(--th1-primary) 49%,
            white 49%,
            white 51%,
            var(--th1-bg) 51%
          );
        }
        &.bg2 {
          background: linear-gradient(
            90deg,
            var(--th2-primary),
            var(--th2-primary) 49%,
            white 49%,
            white 51%,
            var(--th2-bg) 51%
          );
        }
        &.bg3 {
          background: linear-gradient(
            90deg,
            var(--th3-primary),
            var(--th3-primary) 49%,
            white 49%,
            white 51%,
            var(--th3-bg) 51%
          );
        }
        &.bg4 {
          background: linear-gradient(
            90deg,
            var(--th4-primary),
            var(--th4-primary) 49%,
            white 49%,
            white 51%,
            var(--th4-bg) 51%
          );
        }
        &.bg5 {
          background: linear-gradient(
            90deg,
            var(--th5-primary),
            var(--th5-primary) 49%,
            white 49%,
            white 51%,
            var(--th5-bg) 51%
          );
        }
        @media (min-width: 320px) and (max-width: 480px) {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .switch {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    margin-left: 15px;
    margin-right: 3px;
    border-radius: 50px;
    border: 1px solid var(--filter-border) !important;
    @media (max-width: 720px) {
      width: 32px;
      height: 32px;
      margin-left: 10px;
    }

    cursor: pointer;
    .round {
      width: 28px;
      height: 28px;
      border-radius: 50px;
      background: var(--ion-color-primary);
      display: flex;
      align-items: center;
      justify-content: center;
      .moon-img {
        color: var(--text3) !important;
        fill: var(--text3);
      }
      @media (min-width: 320px) and (max-width: 480px) {
        width: 25px;
        height: 25px;
      }
    }
  }

  a {
    text-decoration: none;
  }
  .theme-btn {
    background: var(--ion-color-primary) !important;
    border-radius: 8px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    border: 1px solid var(--filter-border) !important;

    .dot {
      height: 15px;
      width: 15px;
      border-radius: 50%;
      border: 2px solid var(--ion-color-primary) !important;
      display: inline-block;
      margin-left: 5px;
      cursor: pointer;
      align-items: center;
    }
    .MuiButton-label {
      color: var(--text3) !important;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      text-decoration: none;
    }
    border: none;
    transition: all 0.3s ease;
    overflow: hidden;
  }
}
.profile-options {
  border-radius: 8px;
  width: 400px;
  top: 25px !important;
}
.bets-list-popover {
  top: 13px !important;
  border-radius: 8px;
  -webkit-transition: all 0.3s ease 0.15s;
  -moz-transition: all 0.3s ease 0.15s;
  -o-transition: all 0.3s ease 0.15s;
  -ms-transition: all 0.3s ease 0.15s;
  transition: all 0.3s ease 0.15s;
}
.logo-options {
  border-radius: 8px;
  width: 300px;
  top: 25px !important;
  .user-popover-list {
    border-radius: 10px;
    border: 1px solid var(--filter-border) !important;
    padding: 6px;
    align-items: center;
    height: 150px;
    width: 160px;
    background-color: #fff !important;
    .ion-list-item:hover {
      background-color: #fff !important;
      color: #000 !important;
    }
    .MuiTypography-body1 {
      color: #000 !important;
      font-weight: bold;
    }
  }
}
.theme-optionslist {
  border-radius: 8px;
  top: 40px !important;
  left: -10px !important;
  min-height: 450px;

  @media screen and (max-width: 720px) {
    left: 0px !important;
  }
  .theme-drop-down {
    border-radius: 30px;
    border: 1px solid var(--filter-border) !important;
    // display: flex;
    // flex-direction: column;
    padding: 5px;
    align-items: center;
    // height: 130px;
    // width: 180px;
    // background-color: var(--common-dark);
    background-color: #fff;
    .theme-options1 {
      align-items: center;
      // background-color: var(--table-head-bg);
      // background-color: #fff;
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(3, 1fr);
      .circle1 {
        // width: 35px;
        // height: 35px;
        // border-radius: 50px;
        margin-top: 5px;
        margin-bottom: 5px;
        background-color: var(--table-head-bg);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 0px !important;

        .label {
          font-size: 8px;
          color: #000;
          font-weight: bolder;
        }
        .round {
          width: 30px;
          height: 30px;
          border-radius: 50px;
          display: flex;
          align-items: center !important;
          justify-content: center !important;
          .sport-icon2 {
            height: 32px;
            width: 32px;
          }
          .sport-icon {
            height: 22px;
            width: 25px;
          }
          .sport-icon1 {
            height: 32px;
            width: 32px;
          }
        }
      }
    }
    .theme-options {
      border-radius: 30px;
      // border: 1px solid var(--filter-border) !important;
      display: flex;
      flex-direction: column;
      // padding: 5px;
      align-items: center;
      // height: 100px;
      // width: 180px;
      // background-color: var(--common-dark);
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(5, 1fr);
      .circle1 {
        width: 28px;
        height: 28px;
        border-radius: 50px;
        background: var(--common-dark);
        border: 1px solid var(--filter-border) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2px;
        cursor: pointer;
        .round1 {
          width: 20px;
          height: 20px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--text-primary);
          .moon-img {
            color: var(--text-primary);
            fill: var(--text-primary);
          }
        }
        .round {
          width: 20px;
          height: 20px;
          border-radius: 50px;
          &.bg1 {
            background: linear-gradient(
              90deg,
              var(--th1-primary),
              var(--th1-primary) 49%,
              white 49%,
              white 51%,
              var(--th1-bg) 51%
            );
          }
          &.bg2 {
            background: linear-gradient(
              90deg,
              var(--th2-primary),
              var(--th2-primary) 49%,
              white 49%,
              white 51%,
              var(--th2-bg) 51%
            );
          }
          &.bg3 {
            background: linear-gradient(
              90deg,
              var(--th3-primary),
              var(--th3-primary) 49%,
              white 49%,
              white 51%,
              var(--th3-bg) 51%
            );
          }
          &.bg4 {
            background: linear-gradient(
              90deg,
              var(--th4-primary),
              var(--th4-primary) 49%,
              white 49%,
              white 51%,
              var(--th4-bg) 51%
            );
          }
          &.bg5 {
            background: linear-gradient(
              90deg,
              var(--th5-primary),
              var(--th5-primary) 49%,
              white 49%,
              white 51%,
              var(--th5-bg) 51%
            );
          }
          &.bg6 {
            background: linear-gradient(
              90deg,
              var(--th6-primary),
              var(--th6-primary) 49%,
              white 49%,
              white 51%,
              var(--th6-bg) 51%
            );
          }
          &.bg7 {
            background: linear-gradient(
              90deg,
              var(--th7-primary),
              var(--th7-primary) 49%,
              white 49%,
              white 51%,
              var(--th7-bg) 51%
            );
          }
          &.bg8 {
            background: linear-gradient(
              90deg,
              var(--th8-primary),
              var(--th8-primary) 49%,
              white 49%,
              white 49%,
              white 51%,
              var(--th8-bg) 51%
            );
          }
          &.bg9 {
            background: linear-gradient(
              90deg,
              var(--th9-primary),
              var(--th9-primary) 49%,
              white 49%,
              white 51%,
              var(--th9-bg) 51%
            );
          }
          &.bg10 {
            background: linear-gradient(
              90deg,
              var(--th10-primary),
              var(--th10-primary) 49%,
              white 49%,
              white 51%,
              var(--th10-bg) 51%
            );
          }
        }
      }
    }
  }
}
.MuiPopover-root .MuiPopover-paper {
  background: transparent !important;
}
.theme-opetion {
  width: 5%;
  display: flex;
  justify-content: flex-end;
  position: fixed !important;
  top: 20% !important;
  right: 0;
  z-index: 10000;
  cursor: pointer;
  @media screen and (max-width: 720px) {
    // display: none;
    top: 50% !important;
    cursor: pointer;
  }
  .theme-menu {
    // right: 0;

    transition: 0.3s ease-in;
    -webkit-animation: newlaunch 1s;
    -moz-animation: newlaunch 1s;
    animation: newlaunch 1s;
    animation-iteration-count: infinite;
    // background-color: var(--text-common-dark);
    background-color: #fff;
    height: 50px;
    width: 50px;
    border-radius: 10px 0px 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    // @media screen and (max-width: 720px) {
    //   height: 50px;
    //   width: 45px;
    //   .icon {
    //     height: 18px;
    //     width: 18px;
    //   }
    // }
    .icon {
      height: 27px;
      width: 35px;
      @media screen and (max-width: 720px) {
        height: 30px !important;
        width: 25px;
      }
    }

    @keyframes newlaunch {
      0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        background-color: red !important;
      }
      50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        background-color: #f3bd42 !important;
      }
      100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        background-color: red !important;
      }
    }
  }

  .drop-down-list {
    background-color: #fff;
    height: 190px;
    width: 160px;
    border-radius: 10px 0px 0px 10px;
    animation: fade-in 1s;
    @media screen and (max-width: 720px) {
      height: 120px;
      width: 120px;
    }
    .ion-list-item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 48px;
      padding: 6px;
      @media screen and (max-width: 720px) {
        height: 30px;
      }
      cursor: pointer;
      &.ion-list-item:last-child {
        border-bottom: 0px !important;
      }
      &.ion-list-item:first-child {
        background-color: var(--ion-color-primary);
        border-radius: 10px 0px 0px 0px;
      }
      border-bottom: 1px solid #d4d4d4;
      .text {
        font-size: 14px;
        font-weight: bold;
        margin-left: 20px;
        @media screen and (max-width: 720px) {
          font-size: 11px;
          margin-left: 10px;
        }
      }
      .arrow {
        height: 18px;
        width: 18px;
        opacity: 0.8;
        @media screen and (max-width: 720px) {
          height: 15px;
          width: 15px;
        }
      }
      .sport-icon {
        height: 27px;
        width: 30px;
        @media screen and (max-width: 720px) {
          height: 20px;
          width: 22px;
        }
      }
    }
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


@media only screen and (max-width: 420px) {
  .small_screen_logo {
    height: var(--header-logo-height) !important;
    width: var(--header-logo-width) !important;
  }
  .btn_align_logo{
    margin-right: 4px !important;
  }
  .switch_align_logo{
    margin-left: 3px !important;
  }
}