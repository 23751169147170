.events-table-ctn {
  .MuiPaper-root {
    background-color: var(--ion-background);
    font-family: var(--font-family);
  }
  .no-border .MuiPaper-root {
    border: 0px;
    &.border-radius {
      border-radius: 8px !important;
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
  .eventType-accordion {
    box-shadow: none;
    margin-top: 8px !important;
    .eventType-header {
      font-style: normal;
      font-size: 18px;
      line-height: 20px;
      color: var(--text-primary) !important;
      padding: 2px 4px 3px 8px;
      height: 26px !important;
      display: flex;
      align-items: center;
      overflow: hidden;
      .inplay-event-heading {
        display: flex;
        align-items: center;
        color: var(--text-primary);
      }
      .MuiAccordionSummary-expandIcon {
        opacity: 1;
        .expand-icon {
          color: var(--text-primary) !important;
        }
      }
    }
    .inplay-events-tbl-container,
    .events-table-content {
      padding: 0px;
      .all-markets-nav-link {
        cursor: pointer !important;
        color: var(--text-primary) !important;
        .odds-web-view {
          .teams .team-name {
            @media (max-width: 720px) {
              width: 150px;
            }
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: var(--text-primary) !important;
            font-style: normal;
            font-size: 12px;
            line-height: 17.5px;
            padding-left: 3px !important;
          }
        }
      }
    }
  }

  .table-ctn,
  .tbl-ctn {
    .MuiPaper-root {
      margin-top: 0;
      box-shadow: none;
      &.border-radius {
        border-radius: 8px !important;
        border: 1px solid rgba(255, 255, 255, 0.2);
      }
    }

    .MuiTableContainer-root {
      background: var(--table-body-background) !important;
      border-bottom-right-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
      border-top-right-radius: 0px !important;
      border-top-left-radius: 0px !important;
      .MuiTable-root {
        .MuiTableHead-root {
          border: none;
          border-radius: 0px !important;
          .MuiTableRow-head {
            border-bottom: 1px solid var(--filter-border) !important;
            // border-top: 1px solid var(--filter-border) !important;
            .MuiTableCell-head {
              white-space: nowrap;
              font-size: 12px;
              color: var(--teble-head);
              font-family: var(--font-family) !important;
              text-transform: uppercase;
              .team-cell-mob {
                display: flex;
                flex-direction: row;
                align-items: center;
                img {
                  margin-right: 5px;
                }
              }
            }
          }
        }

        .MuiTableBody-root {
          border: none;
          .MuiTableRow-root {
            border-bottom: 1px solid var(--filter-border);
            &:last-child {
              border-bottom: 0px !important;
            }
            cursor: pointer;
            .all-markets-nav-link {
              .mob-view {
                display: flex !important;
                .odds-block {
                  @media (max-width: 900px) {
                    display: inline-flex;
                    width: 100%;
                    align-items: center;
                    border-radius: 0px;
                    padding: 0px;
                    margin-top: 0px;
                    padding: 0px;
                  }
                  .mob-exch-odd-view {
                    @media (max-width: 900px) {
                      width: 100%;
                    }
                  }
                }
                @media (min-width: 900px) {
                  display: none !important;
                }
              }
            }

            .schedule-cell {
              white-space: break-spaces;
              display: flex;
              align-items: center;
              padding: 14px !important;
              font-family: var(--font-family);
              width: 110px;
              .schedule-ctn {
                display: flex;
                flex-direction: row;
                align-items: center;
              }
            }
            .multi-add-icon {
              height: 30px;
              cursor: pointer;
            }
            .multi-remove-icon {
              height: 30px;
              cursor: pointer;
            }
            .odds-cell-suspended {
              min-height: 36px;
              padding: 2px 2px;
              min-width: 120px;
              pointer-events: none;
              cursor: default;
              background: var(--side-menu);
              border: 1px solid #000;
              // width: 90%;
              .suspended-text {
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--text1);
                font-size: 20px;
                text-transform: uppercase;
                font-family: var(--font-family);
              }
            }
            .suspended-text-row {
              min-height: 36px;
              padding: 2px 2px;
              pointer-events: none;
              cursor: default;
              border: 1px solid #000;
              background: var(--side-menu);
              width: 100%;
              .suspended-text {
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--text1);
                font-size: 22px;
                text-transform: uppercase;
                font-family: var(--font-family);
              }
            }
            .icon-cell {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
              @media screen and (max-width: 720px) {
                display: none;
              }
              .icon-market {
                color: var(--text1);
                height: 22px;
                width: 22px;
                margin-right: 3px;
                filter: var(--game-category-icons1);
              }
              .icon-ctn {
                display: flex;
                flex-direction: row;
                align-items: center;
                vertical-align: baseline;
                background-color: var(--common-dark);
                border-radius: 8px;
                padding: 3px;
                opacity: 1;
                border: 1px solid var(--filter-border1);
                .mo-circle,
                .bm-circle,
                .f-circle,
                .p-circle {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center !important;
                  height: 18px;
                  width: 18px;
                  border-radius: 50%;
                  background: rgba(255, 192, 25, 0.2);
                  border: 1px solid var(--ion-color-primary);
                  font-size: 7px;
                  margin-left: 2px;
                  margin-right: 2px;
                  &.p-circle {
                    background-color: rgba(255, 109, 87, 0.15);
                    border: 1px solid #ff6d57;
                  }
                }
              }
            }
            .schedule-cell1 {
              white-space: break-spaces;
              display: flex;
              align-items: center;
              width: 90px;
              font-family: var(--font-family);
              @media screen and (max-width: 900px) {
                display: none !important;
              }

              .schedule-ctn {
                display: flex;
                flex-direction: row;
                align-items: center;
              }
              .inply {
                color: var(--text-primary) !important;
                font-style: normal;
                font-size: 12px;
              }
              .date {
                font-size: 12px !important;
                color: var(--ion-color-primary);
                margin-left: 10px;
                padding-top: 5px;
              }
              img {
                margin-right: 5px;
                margin-left: 5px;
              }
            }
          }

          .MuiTableCell-root {
            padding: 4px;

            .odds-block {
              display: inline-flex;
              width: 100%;
              justify-content: center;
              align-items: center;
              .back-odd,
              .lay-odd {
                cursor: default !important;
                width: 60px;
                height: 30px;
                padding: 2px 4px;
                font-style: normal;
                align-items: center;
                display: flex;
                justify-content: center;
                align-items: center !important;
                border-radius: 8px;
                cursor: pointer;
                .price {
                  color: var(--text-common-dark);
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 15px;
                  font-weight: 700;
                  cursor: pointer;
                }
                .size {
                  font-family: 'Inter';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 10px;
                  line-height: 12px;
                  text-align: center;
                  color: var(--text-common-dark);
                }
              }
            }
          }
        }
      }
    }

    .live-img {
      width: 34px;
      height: 18px;
    }

    .all-markets-nav-link {
      text-decoration: none;
      line-height: 34px;
      color: var(--text-primary) !important;
      .teams .team-name {
        max-width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: var(--text-primary) !important;
        font-style: normal;
        font-size: 12px;
        line-height: 17.5px;
        padding-left: 5px !important;
      }
    }
  }
}

.back-odd {
  background: var(--back-odd-background);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.lay-odd {
  background: var(--lay-odd-background);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.events-table-ctn {
  display: flex;
  .events-section {
    width: 100%;
    //margin-top: 60px;
    .card {
      background-color: var(--common-dark);
      padding: 12px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      position: relative;
      .comingsoon {
        color: var(--teble-head);
        padding: 16px;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .nav-list-event-table {
        margin-bottom: 10px !important;
      }
      .carw {
        position: absolute;
        top: -50px;
        height: 60px;
        left: 0;
        .arrow {
          display: flex;
          align-items: center;
          position: relative;
          min-width: 230px !important;
          max-width: 100%;
          height: 60px;
          background-color: var(--common-dark);
          line-height: 40px;
          margin-bottom: 30px;
          text-align: left;
          color: var(--text-primary);
          border-top-left-radius: 10px;
          border-top-right-radius: 35px;
          padding-left: 8px;
          font-size: 20px;
          font-weight: 600;
          text-transform: capitalize;
          @media screen and (max-width: 720px) {
            font-size: 18px;
            min-width: 190px !important;
          }
          .img-ctn {
            background-color: var(--side-menu);
            margin-right: 5px;
            margin-left: 5px;
            border-radius: 8px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              height: 14px;
              width: 16px;
            }
          }
        }
        .arrow-right:after {
          content: '';
          width: 0px;
          height: 0px;
          position: absolute;
          right: -38px;
          top: 0;
          border-top-left-radius: 10px;
          border-right: 60px solid transparent;
          border-bottom: 60px solid var(--common-dark);
        }
      }
    }
    @media screen and (max-width: 720px) {
      .card {
        padding: 10px;
        position: relative;
        margin-top: 20px;
        @media screen and (max-width: 720px) {
          .carw {
            position: absolute;
            top: -40px;
            height: 40px;
            left: 0;
            .arrow {
              display: flex;
              align-items: center;
              position: relative;
              min-width: 190px !important;
              max-width: 100%;
              height: 40px;
              background-color: var(--common-dark);
              line-height: 40px;
              text-align: left;
              font-weight: 600;
              color: var(--text-primary);
              border-top-left-radius: 10px;
              border-top-right-radius: 35px;
              padding-left: 5px;
              font-size: 16px;
              .color-active {
                color: var(--ion-color-primary);
                margin-left: 2px;
                margin-right: 2px;
              }

              .img-ctn {
                background-color: var(--side-menu);
                margin-right: 5px;
                margin-left: 5px;
                border-radius: 8px;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  height: 14px;
                  width: 16px;
                }
              }
            }
            .arrow-right:after {
              content: '';
              width: 0px;
              height: 0px;
              position: absolute;
              right: -25px;
              top: 0;
              border-top-left-radius: 10px;
              border-right: 50px solid transparent;
              border-bottom: 40px solid var(--common-dark);
            }
          }
        }
      }
    }
  }
  .sport-info-section {
    width: 20%;
    margin-left: auto;
  }
}
.inplay-events-table-ctn {
  flex-direction: column;
}

@media (max-width: 1200px) {
  .events-table-ctn
    .events-table-content
    .MuiPaper-root
    .events-table
    .MuiTableRow-root
    .odds-cell {
    display: none;
    min-width: 120px;
  }

  .events-table-ctn
    .events-table-content
    .MuiPaper-root
    .events-table
    .MuiTableRow-root
    .odds-cell-head,
  .events-table-ctn
    .events-table-content
    .MuiPaper-root
    .events-table
    .MuiTableRow-root {
    .schedule-cell {
      display: none;
    }
    .MuiTableCell-root {
      .odds-block {
        .back-odd,
        .lay-odd {
          min-height: 26px;
          cursor: pointer;
        }
      }
    }
  }

  .odds-row {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 6px;
    .odds-block {
      display: flex;
      flex: 1 1 33%;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin: 0px;
      padding: 0px;
      font-size: 12px;
    }
  }
}

@media (max-width: 1120px) {
  .events-table-ctn {
    .border-radius {
      border-radius: 8px !important;
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
    .banner-container {
      display: none;
    }
    .eventType-accordion {
      .eventType-header {
        font-size: 16px;
        line-height: 18px;
        padding: 7px 14px 7px 7px;
        height: 36px !important;
      }
      .sport-inplay-icon {
        height: 24px;
        width: 24px;
        margin-right: 12px;
      }
    }
    .events-table-content {
      .MuiPaper-root {
        margin-top: 10px;
        .events-table {
          overflow: hidden;
          .MuiTableRow-root {
            .schedule-cell {
              display: none;
            }
            .odds-cell-head {
              display: none;
            }
            .teams-cell {
              .web-view {
                display: none;
              }
              .live-img {
                width: 40px;
                margin-left: 10px;
              }
              min-width: 100% !important;
              max-width: 100% !important;
              .all-markets-nav-link {
                text-decoration: none;
                color: var(--text-primary) !important;
                .team-name {
                  white-space: nowrap;
                  color: var(--text-primary) !important;
                  font-style: normal;
                  font-size: 12px;
                  line-height: 17.5px;
                  padding-left: 5px !important;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .mob-icon {
                  width: 30px;
                  margin-right: 2px;
                  display: flex;
                  align-items: center;
                  .multi-add-icon {
                    height: 20px;
                  }
                  .multi-remove-icon {
                    height: 20px;
                  }
                }
                .event-info {
                  width: 100%;
                  padding: 0px;

                  .event-name {
                    text-decoration: none;
                    color: var(--text-primary) !important;
                    font-size: 14px;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @media screen and (max-width: 720px) {
                      font-size: 10px;
                      line-height: 15px;
                      margin-right: 3px;
                      max-width: 210px;
                    }
                  }
                  .schedule-time {
                    text-decoration: none;
                    color: var(--ion-color-primary);
                    font-size: 12px;
                    display: flex;
                    flex-wrap: wrap;
                    line-height: 17px;
                    margin-right: 8px;
                    @media screen and (max-width: 900px) {
                      font-size: 10px;
                    }
                  }
                }
              }
            }
            .score-cell {
              display: none;
              max-width: 40px !important;
              .score {
                max-width: 100%;
                font-size: 10px;
                line-height: 14px;
              }
            }

            .odds-cell {
              display: none;
              min-width: 120px;

              .odds-block {
                .back-odd,
                .lay-odd {
                  min-width: 40px;
                  max-width: 50px;
                  min-height: 36px;
                  padding: 2px 2px;
                  display: flex;
                  align-items: center !important;

                  .price {
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: 700;
                  }
                  .size {
                    font-size: 10px;
                    line-height: 12px;
                  }
                  &.disabled {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .lock-icon {
                      height: 16px;
                      width: 18px;
                    }
                  }
                }
              }
            }
            .all-markets-link-cell {
              min-width: 20px;
              padding-left: 0px !important;
              ion-img {
                height: 12px;
                width: 7px;
                margin-right: 0px;
                margin-left: auto;
              }
            }
          }
          .MuiTableRow-root {
            border: 0px;
          }
          .MuiTableHead-root .MuiTableRow-root {
            .MuiTableCell-root {
              padding: 8px;
              font-size: 14px;
              line-height: 15px;
            }
          }
          .MuiTableBody-root .MuiTableRow-root {
            .MuiTableCell-root {
              padding: 6px;
              padding-right: 0px;
            }
          }
        }
      }
    }

    .bs-accordion {
      // bottom: 50px;
      // width: 100%;
      // right: 0px;
      display: none;
    }
    .footer-row {
      margin-top: 10px;
      .pagination-btn {
        font-size: 11px;
        height: 24px;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .events-table-ctn,
  .inplay-events-table-ctn {
    width: 100%;
    .table-ctn,
    .tbl-ctn {
      .MuiTableContainer-root {
        .MuiTable-root {
          .MuiTableBody-root {
            .MuiTableCell-root {
              font-size: 14px;
              padding: 7px 8px;
              .teams {
                border: 0px;
                font-family: var(--font-family);
                cursor: pointer;
                .live-img {
                  margin-left: 1%;
                  object-fit: contain;
                  width: 34px;
                  height: 14px;
                }
              }
              .event-date {
                font-size: 12px;
                border: 0px;
                font-family: var(--font-family);
              }
              .odds-block {
                display: inline-flex;
                width: 100%;
                align-items: center;
                border-radius: 0px;
                padding: 0px;
                margin-top: 0px;
                padding: 0px;
                .back-odd,
                .lay-odd {
                  width: 48px;
                  max-width: 52px;
                  height: 31px;
                  border-radius: 8px;
                  display: flex;
                  align-items: center;
                  cursor: pointer;

                  .price {
                    font-size: 10px;
                    line-height: 24px;
                    align-items: center;
                    font-weight: 700;
                    cursor: pointer;
                  }
                }
                .mob-exch-odd-view {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .sport-info-section {
      display: none;
    }
  }
}
.inplay-table {
  .MuiTableHead-root {
    &.web-view {
      @media (max-width: 900px) {
        display: none !important;
      }
    }
  }
  .inply-row {
    &.mob-view {
      display: flex !important;
      justify-content: center !important;
      @media (min-width: 900px) {
        display: none !important;
      }
    }
  }
}

.schedule {
  .schedule-start {
    font-size: 11px;
  }
}
