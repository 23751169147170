.card11 {
  background-color: var(--side-menu);
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  .icon-ctn {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center !important;
    background-color: var(--common-dark);
    height: 40px;
    width: 40px;
    border-radius: 6px;
    margin-left: 10px;
    margin-right: 10px;
    @media screen and (max-width: 720px) {
      height: 32px;
      width: 32px;
    }
    .icon {
      fill: var(--ion-color-primary);
      color: var(--ion-color-primary);
      font-size: 22px;
    }
  }
  .text1 {
    color: var(--text1);
    font-size: 13px;
  }
}
