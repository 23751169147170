.footer-ctn {
  background: var(--footer-bg) !important;
  border-top: 1px solid var(--footer-bg) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 8px;
  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
  .copyright-ctn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--ion-text-white);
    text-align: center;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    @media screen and (max-width: 720px) {
      font-size: 10px;
      flex-direction: column;
    }
    .navlink {
      margin-left: 5px;
      color: var(--ion-color-primary);
    }
  }
  .logo-ctn {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    .logo {
      height: 60px !important;
    }
  }
  .social-media {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    .icon-ctn {
      margin-left: 6px;
      margin-right: 6px;
      width: 40px;
      height: 38.34px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 720px) {
        margin-left: 6px;
        margin-right: 6px;
      }
      .sportbook-img {
        height: 30px;
        width: 30px;
        border-radius: 50%;
      }
    }
  }
}
.payment-footer {
  display: flex;
  justify-content: center;
  width: 100%;
  .w-30,
  .w-70 {
    width: 75%;
    &.w-30 {
      width: 75% !important;
    }
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media screen and (max-width: 720px) {
      width: 90%;
    }
  }
  .payment-ctn2,
  .payment-ctn1,
  .payment-ctn {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &.payment-ctn1 {
      grid-template-columns: repeat(2, 1fr);
    }
    &.payment-ctn2 {
      grid-template-columns: repeat(1, 1fr);
    }
    @media screen and (max-width: 720px) {
      grid-template-columns: repeat(1, 1fr);
      &.payment-ctn1 {
        grid-template-columns: repeat(2, 1fr);
      }
      &.payment-ctn2 {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .payment-card {
      background-color: var(--card);
      display: flex;
      align-items: center !important;
      justify-content: center;
      padding: 6px;
      border-radius: 8px;
      border: 1px solid var(--filter-border);
      margin-top: 10px;
      margin-bottom: 10px;
      min-width: 230px;
      margin-left: 10px;
      margin-right: 10px;
      .grid {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: var(--text1);
        font-weight: 400;
        text-decoration: none;
        margin-left: 4px;
        margin-right: 4px;
        .icon-ctn {
          height: 32px;
          width: 32px;
        }
        @media screen and (max-width: 720px) {
          cursor: pointer;
          color: var(--text1);
          font-weight: 400;
          text-decoration: none;
          margin-left: 2px;
          margin-right: 2px;
          font-size: 12px;
          .icon-ctn {
            height: 28px;
            width: 28px;
          }
        }
      }
    }
  }
}
