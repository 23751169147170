@import '../src/assets/global_styles/index.scss';
@import './theme.css';
@import './scorcardtheme.css';

.router-ctn {
  width: 100%;
  height: 100%;
  font-family: var(--font-family) !important;
}

.Toastify__toast-container--top-center {
  top: 3em;
}

.Toastify__toast--success {
  background: var(--ion-toast--success) !important;
  color: var(--text-primary) !important;
}
html {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.dev-tools-msg-modal {
  .carw {
    height: 60px;
    .arrow {
      position: relative;
      width: 250px !important;
      background-color: var(--common-dark);
      line-height: 60px;
      margin-bottom: 30px;
      border-top-left-radius: 10px;
      border-top-right-radius: 35px;
      padding-left: 20px !important;
      font-size: 20px;
      color: var(--teble-head);
      font-weight: 500;
      .sub-title {
        font-size: 14px;
      }
    }
    .arrow-right:after {
      content: '';
      width: 0px;
      height: 0px;
      position: absolute;
      right: -38px;
      top: 0;
      border-top-left-radius: 10px;
      border-right: 60px solid transparent;
      border-bottom: 60px solid var(--common-dark);
    }
  }
  .modal-content-ctn {
    min-height: 320px;
    overflow: hidden;
    color: var(--ion-text-white);
    background-color:var(--common-dark) !important;
    padding-bottom: 50px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    .dev-tools-warning-msg {
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      .alert {
        margin-top: 60px;
        font-size: 24px;
        @media screen and (max-width: 720px) {
          font-size: 16px;
        }
      }
      .msg {
        font-size: 16px;
        color: var(--text-primary);
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        position: absolute;
        text-align: center;
        margin-top: 120px;
        white-space: break-spaces;
        @media screen and (max-width: 720px) {
          margin-top: 90px;
        }
        .dsc {
          white-space: break-spaces;
          color: var(--teble-head);
          font-size: 16px;
          padding: 20px;
          @media screen and (max-width: 720px) {
            font-size: 13px;
          }
        }

        .dsc-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          line-height: 23px;
          font-size: 11px;
          color: var(--text-primary);

          .btn1 {
            background-color: var(--ion-color-primary) !important;
            width: 25%;
            text-align: center;
            margin-top: 10px;
            margin-right: 8px;
            color: #000;
            font-weight: bold;
            @media screen and (max-width: 720px) {
              width: 35%;
            }
          }
          .btn {
            width: 25%;
            text-align: center;
            margin-top: 10px;
            background: rgba(255, 255, 255, 0.4);
            border-radius: 8px;
            margin-right: 10px;
            color: var(--text);
            font-weight: bold;
            border: 1px solid var(--filter-border) !important;
            @media screen and (max-width: 720px) {
              width: 35%;
            }
          }
        }
        .btn {
          background-color: var(--ion-color-primary);
          width: 25%;
          text-align: center;
        }
      }
    }
  }
}
ion-content {
  font-family: var(--font-family) !important;
}
