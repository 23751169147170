.sidebar-ctn {
  .header-menu-icon {
    color: var(--ion-text-white);
    font-size: 22px;
    margin-right: 10px;
    margin-top: 10px;
    margin-left: 10px;
  }
}
.some-custom-class {
  .slide-pane__header {
    flex: 0 0 35px;
    align-items: center;
    background: var(--ion-color-primary);
    height: 35px;
    border-bottom: 1px solid #ddd;
    text-transform: capitalize;
    display: none;
    .slide-pane__close {
      margin-left: 8px !important;
    }
    .slide-pane__title-wrapper {
      // margin-left: 68px;
      font-size: 16px;
    }
  }
  .slide-pane__content {
    padding: 8px;
    background-color: var(--side-menu);
  }
}
.slide-pane__overlay {
  top: 55px !important;
  .some-custom-class .slide-pane__content {
    background-color: var(--side-menu);
  }
}

@media only screen and (max-width: 420px) {
  .small_size_logo_align{
    margin-right: 4px !important; 
  }
}