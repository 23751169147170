.casino-providers-ctn {
  background: var(--card) !important;
  border: 1px solid var(--filter-border);
  border-radius: 10px;
  .casino-title {
    padding: 10px;
    border-bottom: 1px solid var(--filter-border);
    color: var(--text-primary);
    .casino {
      color: var(--ion-color-primary);
      font-family: var(--font-family);
    }
  }
  .sport-img {
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
  }

  .casino-providers {
    background-color: var(--ion-background);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .grid1 {
      grid-template-columns: repeat(1, 1fr);
      .active {
        background-color: var(--ion-background) !important;
      }
      .card1 {
        background: var(--ion-background-tertiary);
        padding: 20px;
        border-radius: 0px;
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 2px;
        color: var(--ion-color-primary);
        cursor: pointer;
      }
    }
    .grid {
      display: grid;
      grid-gap: 1px;
      grid-template-columns: repeat(2, 1fr);

      .active {
        background-color: var(--ion-background) !important;
      }
      .card {
        background: var(--ion-background-tertiary);
        margin: 1px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px;
        border-radius: 0px;
        cursor: pointer;
      }
      .odd-card {
        background: var(--ion-background-tertiary);
        margin: 0px 1px 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px;
        cursor: pointer;
      }
      .sp-card {
        background: var(--ion-background-tertiary);
        margin: 1px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px;
        border-bottom-left-radius: 10px;
        cursor: pointer;
      }

      .zero-card {
        background: var(--ion-background-tertiary);
        margin-right: 1px;
        margin-bottom: 2px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px;
        cursor: pointer;
      }

      .one-card {
        background: var(--ion-background-tertiary);
        margin-left: 1px;
        margin-bottom: 2px;
        margin-right: 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px;
        cursor: pointer;
      }

      .sp-cards {
        background: var(--ion-background-tertiary);
        margin: 2px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px;
        border-bottom-right-radius: 10px;
        cursor: pointer;
      }
      .img-provider {
        width: 80%;
      }
    }
  }
}

.mt {
  margin-top: 12px;
}
.add-new-market {
  .add-new-market-accordion {
    background: var(--ion-background-secondary) !important;
    .heading-title {
      padding: 8px;
      display: flex;
      width: 100%;
      height: 40px;
      color: var(--text-primary);
      .active-color {
        color: var(--ion-color-primary);
        margin-right: 2px;
      }
    }
    .container {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-right-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
      background: var(--ion-background-secondary) !important;
      .Collapse-ctn {
        border-radius: 10px !important;
        .Collapse-list {
          background: var(--ion-background-tertiary) !important;
          border-radius: 10px !important;
          .MuiListItem-button {
            border-bottom: 1px solid var(--filter-border);
            padding: 2px;
            margin: 0px;
            .MuiTypography-displayBlock {
              color: var(--text-primary);
              font-size: 11px;
              text-transform: uppercase;
            }
            .MuiSvgIcon-root,
            .expand-icon {
              color: var(--teble-head);
              fill: var(--teble-head) !important;
            }
          }
        }
      }
      .list {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        border-bottom-right-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
        padding-left: 2%;
        padding-right: 2%;
        .MuiListItem-button {
          border-bottom: 1px solid var(--filter-border);
          min-height: 30px;
          .MuiTypography-displayBlock {
            color: var(--teble-head);
            font-size: 14px;
            text-transform: uppercase;
          }
          .MuiSvgIcon-root,
          .expand-icon {
            color: var(--teble-head);
            fill: var(--teble-head) !important;
          }
        }
        .active {
          .MuiListItem-button {
            border-bottom: 0px;
            .MuiTypography-body1 {
              color: var(--ion-color-primary) !important;
            }
          }
        }
      }
    }
  }
}
.slide-pane_from_bottom {
  background-color: var(--ion-background-secondary);
}
