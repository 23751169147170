.MuiPickersBasePicker-container {
  background: var(--ion-background) !important;
  color: var(--input-text-color) !important;

  .MuiPickersBasePicker-pickerView {
    .MuiPickersCalendarHeader-switchHeader {
      margin: 0px;
      padding: 12px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      border-bottom: 1px solid var(--input-text-color);
      .MuiPickersCalendarHeader-iconButton {
        color: var(--input-text-color);
        opacity: 1;
        border-radius: 4px;
      }
    }
    .MuiPickersCalendarHeader-daysHeader {
      .MuiPickersCalendarHeader-dayLabel {
        padding-top: 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
        color: var(--text) !important;
      }
    }
    .MuiPickersCalendar-transitionContainer {
      .MuiIconButton-root {
        color: var(--text) !important;
        opacity: 1;
        border-radius: 4px;
      }
      .MuiPickersDay-hidden {
        color: var(--text1);
        opacity: 0.5;
      }
      .MuiPickersDay-daySelected {
        background: var(--ion-color-primary) !important;
        color: #000 !important;
      }
    }
  }
}

.MuiPickersBasePicker-container
  .MuiPickersBasePicker-pickerView
  .MuiPickersCalendarHeader-switchHeader
  .MuiPickersCalendarHeader-iconButton {
  background: var(--ion-color-primary);
  color: #000 !important;
}
.MuiPickersBasePicker-container
  .MuiPickersBasePicker-pickerView
  .MuiPickersCalendarHeader-daysHeader
  .MuiPickersCalendarHeader-dayLabel {
  color: var(--text1);
}
.MuiPickersSlideTransition-transitionContainer > * {
  color: var(--text1);
}

.MuiPickersDay-dayDisabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

.MuiPickersBasePicker-container {
  .MuiButtonBase-root .MuiPickersDay-dayDisabled {
    background: #dac3c3 !important;
  }
}
.MuiFormControl-marginNormal{
 margin-top: 0px !important;
 margin-bottom:0px !important;
}