.change-pwd-form-ctn {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .text-black {
    color: #fff;
  }
  .pwd-input .MuiOutlinedInput-input {
    background-color: var(--disabled-bg) !important;
  }

  .usr-input,
  .pwd-input {
    display: inline-grid;
    margin-top: 30px;
    width: 100%;

    .login-input-field,
    .MuiOutlinedInput-input {
      border-radius: 0px !important;
      background-color: var(--disabled-bg) !important;
      border: 1px solid var(--filter-border);
    }
  }
  .infoicon {
    color: var(--ion-text-white);
    vertical-align: sub;
  }

  .submit-form-btn {
    margin-bottom: 20px;
    width: 100%;
    height: 45px;
    font-size: 16px;
    line-height: 19px;
    border-radius: 10px;
  }

  .accept-terms-input {
    padding-top: 14px;
    width: 100%;
    .MuiFormControlLabel-root {
      margin-left: 0px;
      margin-right: 0px;
      align-items: flex-start;
      .MuiFormControlLabel-label {
        margin-left: 10px;
        color: var(--text1);
      }
      .MuiOutlinedInput-input {
        background-color: var(--disabled-bg) !important;
        border: 1px solid var(--filter-border);
      }
    }

    .MuiFormHelperText-root {
      color: var(--text1);
    }

    .accept-terms-checkbox {
      color: var(--ion-color-primary);
      position: relative !important;
    }
  }
}

.error-msg {
  color: var(--ion-color-danger);
  margin-top: 20px;
  text-align: center;
}
.success-msg {
  color: var(--ion-color-success);
  margin-top: 20px;
  text-align: center;
}

.user-name,
.pwd-field {
  width: 100%;
  height: 48px;
}

.input-tooltip {
  color: var(--text-primary);
  font-size: 20px;
  padding-left: 4px;
}

.change-pwd-form-ctn {
  .MuiOutlinedInput-input {
    background-color: var(--disabled-bg) !important;
    border-radius: 0px !important;
    border: 1px solid var(--filter-border);
    color: #fff !important;
  }
}
.change-pwd-form-ctn .pwd-input .MuiOutlinedInput-input {
  background-color: var(--disabled-bg) !important;
}
.change-pwd-ctn {
  margin-left: 1%;
  width: 50%;
  @media screen and (max-width: 720px) {
    width: 95%;
  }
  .MuiButton-label {
    color: var(--text-common-dark) !important;
  }
  .submit-form-btn {
    width: 100%;
    background-color: var(--ion-color-primary) !important;
    margin-top: 10px !important;
    border-radius: 10px;
    color: var(--text-common-dark) !important;
    .MuiButton-label {
      color: var(--text-common-dark) !important;
      font-size: 14px;
      font-weight: 900;
      text-transform: capitalize;
      font-weight: 600;
      .icon {
        margin-right: 10px;
      }
    }
  }

  .change-pass-ctn,
  form {
    @media only screen and (max-width: 720px) {
      width: 80%;
      margin-left: 2%;
      margin-right: 4%;
    }
    padding-left: 2%;
    padding-right: 2%;
    .form-input {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 15px;
      .input-label {
        font-size: 14px;
        font-family: var(--font-family);
        color: var(--text-primary);
        margin-top: 10px;
      }
      .MuiInputBase-root .MuiInputBase-input,
      .MuiInputBase-root .MuiOutlinedInput-input {
        font-size: 13px;
        font-family: var(--font-family) !important;
        background-color: var(--input-bg) !important;
        border-radius: 8px !important;
        border: 1px solid var(--filter-border) !important;
      }
    }

    .condition-ctn {
      margin-top: 25px;
      .title {
        font-size: 16px;
        line-height: 15px;
        color: var(--text-primary);
      }
      .detail {
        font-size: 12px;
        color: #ff0000;
        margin-top: 8px;
        font-family: var(--font-family);
        line-height: 20px;
      }
    }
    .MuiDialogActions-root {
      justify-content: flex-end !important;
      .MuiButton-label {
        color: var(--text-primary);
        font-size: 16px;
      }
    }
  }
}
@media screen and (max-width: 720px) {
  .change-pwd-ctn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    width: 100%;
    .change-pass-ctn,
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .form-input {
        margin-top: 15px;
      }
      .submit-form-btn {
        background-color: var(--ion-color-primary) !important;
        margin-top: 1% !important;
        border-radius: 10px;
        .MuiButton-label {
          color: var(--ion-text-white) !important;
          font-size: 14px;
        }
      }
      .MuiInputBase-root .MuiInputBase-input,
      .MuiInputBase-root .MuiOutlinedInput-input {
        font-size: 13px;
        font-family: var(--font-family) !important;
        background-color: var(--input-bg);
      }
      .form-input {
        margin-top: 15px;
        input::placeholder {
          color: var(--teble-head);
          opacity: 1;
          font-size: 16px;
        }
      }
    }
  }
}
