.logout-item-row:hover,
.logout-item-row {
  margin: 10px 10px 10px 10px;
  .btn {
    margin-top: 10px;
    border-radius: 8px;
    background-color: var(--ion-color-primary);
    color: var(--common-dark);
    font-weight: bold;
    text-transform: capitalize;
    .icon {
      margin-right: 10px;
    }
  }
}
.header-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -50px;
  right: 5px;
  color: var(--text-primary);
}
.dashboard-ctn {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 720px) {
    width: 280px;
  }
  .header-content {
    padding: 16px;
    color: var(--text-primary);
    margin-left: -2%;
    margin-right: -2%;
    width: 104%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  .user-details-section {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
      margin-top: 5px;
      color: var(--ion-color-primary) !important;
    }
    .name {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      color: var(--text-primary);
      font-family: var(--font-famliy);
      text-transform: capitalize;
      .user-balance {
        color: var(--ion-color-primary);
        font-size: 11px !important;
      }
    }
    .user-balance {
      color: var(--ion-color-primary);
    }
  }
  .logout-section {
    background: var(--sub-header-color) !important;
    color: var(--text-1) !important;
    text-transform: uppercase;
    padding: 10px 6px;
    border-radius: 15px;
    width: 120px;
    .logout-item {
      display: flex;
      align-items: center;
      cursor: pointer !important;
      justify-content: center;
      font-family: var(--font-famliy);
      font-size: 12px;
      line-height: 140%;
      margin-left: auto;
      margin-right: auto;
      
    }
    .logout-icon {
      height: 15px;
      width: 22px;
      fill: var(--text-1) !important;
    }
  }
  .dashboard-cards-ctn {
    padding-top: 12px;
    .dashboard-item {
      padding: 3px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: var(--ion-background);
      margin-top: 8px;
      border-radius: 8px;
      &.change-pwd-col,
      .dashboard-item-link {
        text-decoration: none !important;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        height: 40px;
        color: var(--text-primary);
        width: 100%;
      }
      .ion-ctn {
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        // background: rgba(255, 192, 25, 0.2);
        background-color: #000;
        border-radius: 6px;
        margin-left: 10px;
        margin-right: 10px;
        .dashboard-item-icon {
          height: 18px;
          width: 18px;
          &.sports-icon {
            height: 18px;
            width: 18px;
          }
        }
      }
      .dashboard-item-text {
        color: var(--text-primary);
        text-align: center;
        font-size: 14px;
        font-family: var(--font-famliy);
        font-weight: 500;
        text-decoration: none;
      }
    }
  }
}
.web-dropdown {
  background-color: var(--common-dark);
  border-radius: 18px;
  padding: 12px;
  .title {
    color: var(--ion-color-primary);
    font-size: 16px;
  }
}
