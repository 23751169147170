.report-page {
  margin-top: 10px;
  padding: 2px;
  @media only screen and (max-width: 720px) {
    margin-top: 0px;
    padding: 0px;
  }
}
.reports-ctn {
  background-color: var(--common-dark);
  padding: 12px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
  .m-4 {
    margin-top: 40px;
  }

  .MuiPaper-root {
    box-shadow: none !important;
    background-color: var(--table-body-background);
    border-radius: 12px;
    .MuiTableBody-root .MuiTableCell-body {
      padding: 6px !important;
      border-top: 1px solid var(--filter-border);
    }
  }
  .myb-bets-div {
    .myb-btn-next,
    .myb-btn-prev {
      margin-left: 10px !important;
      color: var(--common-dark) !important;
      border-radius: 8px;
      font-size: 9px !important;
      font-weight: bold;
      height: 30px;
    }
  }
  .MuiPaper-root {
    margin-right: 0.6rem;
    border: 1px solid var(--filter-border);
    background: var(--table-body-background) !important;
    border-radius: 12px;
    .MuiTableHead-root .MuiTableRow-head {
      .MuiTableCell-head {
        font-size: 12px !important;
        font-family: var(--font-family);
        color: var(--teble-head) !important;
        border-bottom: 1px solid var(--filter-border) !important;
        text-transform: uppercase;
        padding: 8px !important;
        .MuiIconButton-root {
          padding: 0px;
          color: var(--teble-head) !important;
        }
        @media only screen and (max-width: 720px) {
          font-size: 11px !important;
        }
      }
    }
    .MuiTableBody-root {
      br {
        content: '';
        display: block;
        font-size: 100%;
        height: 0.2em;
      }
      .lay-bg,
      .back-bg {
        // background: var(--back-odd-background);
        border-top: 1px solid var(--filter-border);
        color: var(--text-primary) !important;
        // &.lay-bg {
        //   background: var(--lay-odd-background) !important;
        //   border-top: 1px solid var(--filter-border);
        // }
        .MuiTableCell-body {
          font-size: 12px;
          border-spacing: 30px;
          font-family: var(--font-family);
          // color: #000 !important;
          padding: 6px !important;
          align-items: center;
          @media only screen and (max-width: 720px) {
            font-size: 11px !important;
          }
          .odds-ctn {
            display: flex;
            align-items: center;
            flex-direction: row;
          }
          &.profit {
            color: var(--ion-color-success) !important;
          }
          &.loss {
            color: var(--ion-color-danger) !important;
          }
        }
      }
      .loss-bg,
      .profit-bg {
        color: var(--text-primary) !important;
        border-top: 1px solid var(--filter-border);
        .MuiTableCell-body {
          font-size: 12px;
          border-spacing: 30px;
          font-family: var(--font-family);
          color: var(--text-primary);
          padding: 6px !important;
          @media only screen and (max-width: 720px) {
            font-size: 11px !important;
          }
          &.profit {
            color: var(--ion-color-success) !important;
          }
          &.loss {
            color: var(--ion-color-danger) !important;
          }
        }
      }

      .row-bg {
        color: var(--text-primary) !important;
        border-top: 1px solid var(--filter-border);
        .MuiTableCell-body {
          font-size: 12px;
          font-family: var(--font-family);
          color: var(--text-primary);
          padding: 6px;
          @media only screen and (max-width: 720px) {
            font-size: 11px !important;
          }
          &.profit {
            color: var(--ion-color-success) !important;
          }
          &.loss {
            color: var(--ion-color-danger) !important;
          }
        }
      }

      .MuiTableRow-body {
        border-top: 1px solid var(--filter-border);
        font-size: 14px;
        .MuiTableCell-body {
          font-size: 14px;
          font-family: var(--font-family);
          color: var(--text-primary) !important;
          padding: 6px;
          @media only screen and (max-width: 720px) {
            font-size: 12px !important;
          }
        }
      }
    }
  }
  .no-data-row {
    background-color: var(--table-body-background);
    width: 100%;
    border-bottom: 0px !important;
    color: var(--teble-head) !important;
    height: 40px;
    padding-top: 15px;
    text-align: center;
  }
}
.reports-ctn {
  margin: 60px 5px 0px 5px;
  &.m-0 {
    margin: 0px !important;
  }
  @media only screen and (max-width: 720px) {
    margin: 60px 10px 10px 10px;
    &.m-0 {
      margin: 0px !important;
    }
  }
  .btn-ctn {
    position: absolute;
    top: -50px;
    height: 60px;
    right: 0;
    .save-btn {
      color: var(--text3);
      font-weight: bold;
      background-color: var(--ion-color-primary);
      text-transform: capitalize !important;
      font-size: 14px;
      border-radius: 8px !important;
      .icon {
        margin-right: 8px;
        color: var(--text3);
        fill: var(--text3);
      }
    }
    .cross-btn {
      margin-top: 10px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 8px;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
      margin-right: 5px;
      cursor: pointer;
      .icon {
        fill: var(--text-primary) !important;
        color: var(--text-primary) !important;
      }
    }
  }
  .carw {
    position: absolute;
    top: -50px;
    height: 60px;
    left: 0;
    .arrow {
      position: relative;
      min-width: 250px !important;
      max-width: 100%;
      height: 50px;
      background-color: var(--common-dark);
      line-height: 40px;
      margin-bottom: 30px;
      border-top-left-radius: 10px;
      border-top-right-radius: 35px;
      padding-left: 20px !important;
      font-size: 20px !important;
      font-weight: 600;
      color: var(--ion-color-primary) !important;
      .title-sport {
        display: flex;
        flex-direction: row;
        align-items: center !important;
        color: var(--ion-text-white);
        margin-top: 14px;
      }
      .img-ctn {
        background-color: var(--side-menu);
        margin-right: 10px;
        border-radius: 8px;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 20px;
          width: 20px;
        }
      }
      .sub-title {
        font-size: 14px;
      }
    }
    .arrow-right:after {
      content: '';
      width: 0px;
      height: 0px;
      position: absolute;
      right: -38px;
      top: 0;
      border-top-left-radius: 10px;
      border-right: 60px solid transparent;
      border-bottom: 60px solid var(--common-dark);
    }
  }
  @media screen and (max-width: 720px) {
    .carw {
      position: absolute;
      top: -40px;
      height: 40px;
      left: 0;
      .arrow {
        display: flex;
        align-items: center;
        position: relative;
        min-width: 200px !important;
        max-width: 100%;
        height: 40px;
        background-color: var(--common-dark);
        line-height: 40px;
        text-align: left;
        font-weight: 600;
        color: var(--text-primary);
        border-top-left-radius: 10px;
        border-top-right-radius: 35px;
        padding-left: 4px;
        font-size: 14px !important;
        .sub-title {
          font-size: 10px;
          margin-left: 4px;
          color: var(--text-primary);
        }
        .color-active {
          color: var(--ion-color-primary);
          margin-left: 2px;
          margin-right: 2px;
        }

        .img-ctn {
          background-color: var(--side-menu);
          margin-right: 5px;
          margin-left: 5px;
          border-radius: 8px;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 14px;
            width: 16px;
          }
        }
      }
      .arrow-right:after {
        content: '';
        width: 0px;
        height: 0px;
        position: absolute;
        right: -25px;
        top: 0;
        border-top-left-radius: 10px;
        border-right: 50px solid transparent;
        border-bottom: 40px solid var(--common-dark);
      }
    }
  }
  .comingsoon {
    color: var(--teble-head);
    padding: 16px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .filters-row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center !important;
    background-color: var(--ion-background-secondary);
    border-radius: 8px;
    padding-bottom: 10px;
    padding: 14px;
    .filter-title {
      color: var(--text-primary);
      font-size: 14px;
      font-weight: 400;
    }
    .select-filter {
      .select-text {
        font-size: 13px !important;
      }
      .select-filter-control {
        height: 40px;
        background-color: var(--ion-background);
        color: var(--teble-head) !important;
        border-radius: 8px;
        width: 100%;
        .select-text {
          font-size: 13px !important;
        }
      }
    }
    .date-filter {
      .readonly {
        opacity: 0.6 !important;
      }
      .date-filter-control {
        height: 40px;
        background-color: var(--ion-background);
        color: var(--teble-head) !important;
        border-radius: 8px !important;
        width: 100%;
        .MuiInputBase-root {
          min-height: 38px;
          background-color: var(--ion-background);
          color: var(--teble-head) !important;
          min-width: 100%;
          svg,
          .MuiIconButton-root {
            fill: var(--teble-head) !important;
            color: var(--teble-head) !important;
          }
          height: 30px;
          width: 180px;
          .MuiInputBase-input,
          .MuiOutlinedInput-input {
            padding: 4px 16px;
            color: var(--teble-head) !important;
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .admin-module-ctn {
    padding-bottom: 25px;
  }
  .reports-ctn {
    .header-ctn,
    .content-ctn {
      padding: 0px;
      .points-type-toggle {
        height: 32px;
        border-radius: 4px;
        .MuiBottomNavigationAction-root {
          font-size: 14px;
          line-height: 17px;
          min-width: 70px;
        }
      }
    }
    .filters-row {
      padding: 7px;
      .select-filter {
        flex: 0 0 47%;
        .select-filter-control {
          width: 100%;
          font-size: 10px;
        }
      }
      .date-filter {
        flex: 0 0 47%;
        .date-filter-control {
          .MuiInputBase-root {
            height: 30px;
            width: 100%;
            .MuiInputBase-input,
            .MuiOutlinedInput-input {
              font-size: 10px;
              padding: 4px 16px;
            }
          }
        }
      }
      .readonly {
        background: rgba(207, 207, 207, 0.5);
      }
    }
    .header-ctn {
      padding-left: 0px;
      padding-right: 0px;
      .img-page-title-row {
        margin-top: 0.3rem;
        display: inline-flex;
        align-items: center;
        margin-left: 10px;
        display: flex;
        align-items: center;
        .title-image {
          height: 30px;
          width: 30px;
        }
        .title {
          font-size: 16px;
          line-height: 22px;
          margin-left: 0.3rem;
        }
      }
    }
  }
  .text-ellipsis {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.date-filter-control {
  .MuiInputBase-root {
    border: none;
    .MuiInputBase-input,
    .MuiOutlinedInput-input {
      color: var(--text-primary) !important;
      border: none !important;
    }
  }
}
