.competitions-menu {
  .MuiTypography-body1 {
    color: var(--teble-head);
    text-transform: capitalize !important;
    font-size: 13px;
  }
  .competitions-menu-card {
    margin-inline-start: 0px;
    width: initial;
    margin-top: 0px;
    border: 0px !important;
    .competitions-menu-card-header {
      color: var(--teble-head);
      font-size: 16px;
    }
    .competitions-menu-card-content {
      .MuiButtonBase-root,
      .MuiListItem-root {
        .MuiListItemIcon-root {
          min-width: 35px !important;
        }
      }
      padding-inline-start: 0px;
      padding-inline-end: 0px;
      min-height: 75vh;
      // max-height: 75vh;
      overflow-y: auto;
      padding: 0px;
      .MuiSvgIcon-root,
      .expand-icon {
        color: var(--text-primary);
        fill: var(--text-primary);
      }
      .eventTypes-menu-tabs,
      .competitions-menu-tabs {
        .eventTypes-menu-nav-link,
        .competitions-menu-nav-link {
          text-decoration: none;
          color: var(--teble-head);
          .MuiButtonBase-root,
          .MuiListItem-root {
            .MuiListItemIcon-root {
              min-width: 35px !important;
            }
          }
          .eventTypes-menu-tab,
          .competitions-menu-tab {
            opacity: 1;
            min-width: 40px;
            max-width: 220px;
            text-align: left;
            text-transform: none;
            font-style: normal;
            letter-spacing: 0.01em;
          }

          .competitions-menu-tab {
            min-height: 26px;
            padding: 10px 18px;
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
      .eventTypes-menu-tab-divider {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
      .competitions-menu-tabs {
        max-height: 40vh;
        overflow-y: auto;
      }
      .competitions-menu-msg-text {
        font-size: 16px;
        color: var(--teble-head);
        padding: 16px;
      }
    }
  }
  .mob-competitions-view {
    display: none;
  }

  .search-events-ctn {
    .search-filter {
      width: 210px;
      margin-right: auto;
      margin-bottom: 8px;
    }
  }
}

@media (max-width: 1120px) {
  .competitions-menu {
    width: 100%;
  }
}
.competitions-menu {
  .competitions-menu-card-content {
    background: var(--side-menu);
    border-radius: 8px 8px 8px 8px !important;
    min-height: 83vh;
    overflow-y: auto;
    padding: 6px;
    @media screen and (max-width: 720px) {
      padding: 4px;
    }
  }

  .MuiListItemText-inset {
    padding-left: 0%;
    color: #fff !important;
  }
  .eventTypes-menu-nav-link {
    text-decoration: none;
    .MuiListItem-gutters{
      padding-left: 4px !important;
      padding-right: 4px!important;
    }
  }

  .MuiListItem-button {
    width: 100%;
    text-decoration: none;
    text-transform: capitalize !important;
    .MuiListItem-gutters{
      padding-left: 4px !important;
      padding-right: 4px!important;
    }
    .icon-ctn {
      background-color: var(--card);
      border: 1px solid var(--filter-border1);
      display: flex;
      justify-content: center !important;
      align-items: center !important;
      height: 38px;
      width: 38px;
      border-radius: 8px;
      margin-right: 10px;
      .sport-icon0 {
        height: 20px;
        width: 20px;
      }
      .sport-icon2 {
        height: 22px;
        width: 22px;
      }

      .sport-icon5,
      .sport-icon6,
      .sport-icon7,
      .sport-icon3,
      .sport-icon4 {
        height: 24px;
        width: 24px;
      }

      .sport-icon1 {
        height: 26px;
        width: 26px;
      }
      .sport-icon {
        height: 24px;
        width: 24px;
      }
    }
    .expand-icon {
      color: var(--teble-head) !important;
      fill: var(--teble-head);
    }
    .MuiTypography-body1 {
      font-style: normal;
      font-size: 13px;
      display: flex;
      align-items: center;
      text-transform: uppercase !important;
      color: var(--teble-head) !important;
    }
  }
  .active {
    background-color: var(--ion-background) !important;
    .MuiListItem-button {
      background-color: var(--ion-background) !important;
      border-radius: 8px 8px 8px 8px !important;
      .icon-ctn {
        background-color: var(--common-dark) !important;
      }
      .expand-icon {
        color: var(--teble-head) !important;
        fill: var(--teble-head);
      }
    }
    .MuiTypography-body1 {
      color: var(--ion-text-white) !important;
    }
    // img {
    //   filter: var(--game-category-icons);
    // }
  }
  .Collapse-list {
    background-color: var(--ion-background) !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-top: -10px;
    .active {
      .MuiTypography-body1 {
        color: var(--ion-color-primary);
      }
    }
    .no-competitions {
      background: var(--side-menu);
    }
  }
}
.competitions-menu-card-content {
  .eventTypes-menu-nav-link .MuiButtonBase-root,
  .MuiListItem-root {
    .MuiListItemIcon-root {
      min-width: 40px !important;
    }
  }
}
