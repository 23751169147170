$assetPath: '../../assets/images/common';
ion-content.background {
  --background: var(--common-dark) !important;
  min-height: 100vh;
}
.copy-right {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 720px) {
    display: flex !important;
    justify-content: center !important;
    top: 88%;
    width: 100%;
  }
}
.login-card {
  overflow-y: hidden;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  height: 550px;
  width: 470px;
  background-image: var(--login-card);
  background-size: 100% 100%;
  @media screen and (max-width: 720px) {
    height: 615px;
    width: 410px;
    justify-content: center !important;
  }
  .MuiInputBase-root {
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    border-radius: 14px;
    background: var(--login-input-bg) !important;
    width: 100%;
  }
  ::placeholder {
    color: var(--teble-head);
    opacity: 1;
    font-weight: 400;
    font-size: 16px;
  }

  .MuiFormControl-root {
    color: var(--ion-text-white) !important;
    background-color: var(--login-input-bg) !important;
    border-radius: 14px;
    width: 100%;
    margin-top: 10px;
  }
}
